import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import * as firebase from 'firebase'
import { first } from 'rxjs/operators';
import { ISubscription } from 'rxjs/Subscription';
import { userData } from '../../../interfaces/interfaces';
import { usersDatabase } from '../../../database';

@Injectable()
export class AuthGuard implements CanActivate {
  subscription: ISubscription
  constructor(private router: Router, private usersdb: usersDatabase) {
    
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }

  canActivate(): Promise<any> {
    return new Promise((resolve, reject) => {
      firebase.auth().onAuthStateChanged(user => {
        if (user && user.uid) {
          this.subscription = this.usersdb.getUserFromUID(user.uid).valueChanges().subscribe((users: userData[]) => {
            this.usersdb.currentUser = users[0]
            resolve(true)
          })
        } else {
          resolve(false)
          this.router.navigate(['/login']);
        }
      })
    })
  }
}
