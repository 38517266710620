import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { messagesDialog, MessagesService } from './messages.service';
import { MatDialogModule, MatButtonModule } from '@angular/material';



@NgModule({
  declarations: [
    messagesDialog
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule
  ],
  providers: [
    MessagesService
  ],
  entryComponents: [
    messagesDialog
  ]
})
export class MessagesModule { }
