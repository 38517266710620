import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { dropdownObject } from '../../interfaces/interfaces';

@Injectable()
export class rootDatabase {

    public statementTriggers: Array<dropdownObject> = [
        { title: 'Fader', value: 'fader' },
        { title: 'Mute', value: 'mute' },
        { title: 'Kamera-bytte', value: 'camera' },
        { title: 'Sangbytte', value: 'song' },
        // { title: 'Super av/på', value: 'super' }
    ]

    public statementInputs: Array<dropdownObject> = [
        { title: 'Fader', value: 'fader' },
        { title: 'Mute', value: 'mute' },
        { title: 'Kamera-ID', value: 'camera' },
        { title: 'Artist', value: 'artist' },
        { title: 'Sang', value: 'track' },
    ]

    public statementOutputs: Array<dropdownObject> = [
        { title: 'Holographics', value: 'holographics' },
        { title: 'OSC', value: 'osc' },
        // { title: 'Arduino', value: 'arduino' }
    ]

    public statementOperators: Array<dropdownObject> = [
        { title: 'Er lik', value: '==', show: ['fader', 'mute', 'camera', 'artist', 'track'] },
        { title: 'Er ulik', value: '!=', show: ['fader', 'mute', 'camera', 'artist', 'track'] },
        { title: 'Større enn', value: '>=', show: ['fader', 'camera'] },
        { title: 'Mindre enn', value: '<=', show: ['fader', 'camera'] }
    ]

    type?: 'qlab' | 'arduino' | 'holographics';

    constructor(private afs: AngularFirestore){
        
    }

     
}