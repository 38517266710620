import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as firebase from 'firebase'

@Injectable()
export class AuthService {

  errCode
  constructor(
    public router: Router) {
  }

  resetPassword(email) {
    return firebase.auth().sendPasswordResetEmail(email)
  }

  signup(email: string, password: string) {
    firebase.auth().createUserWithEmailAndPassword(email, password)
      .then(value => {
        console.log('Success!', value);
      })
      .catch(err => {
        console.log('Something went wrong:',err.message);
      }); 
  }  

  login(email: string, password: string) {
    return firebase.auth().signInWithEmailAndPassword(email, password)
      .then(res => {
        localStorage.setItem('userUID', res.user.uid);
        console.log('Logget inn!');
        this.router.navigate(['/super']);
        return true;
      })
      .catch(err => {
        console.log(err)
        this.errCode = err.code;
        return false
      });
  }
  
  loginWithToken(token: string) {
    return firebase.auth().signInWithCustomToken(token)
  }

  signInWithGoogle() {
    // return this.signIn(AuthProviders.Google);
    // this.afAuth.auth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
  }

  signOut() {
    console.log('Logget ut..')
    firebase.auth().signOut().then(res => {
      this.router.navigate(['/login']);
      localStorage.clear();
    });
  }
}
