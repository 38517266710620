import { Injectable, Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
// declare let Messenger: any;

// const theme = 'air';
// Messenger.options = { theme: theme  };

export interface DialogData {
  message: string;
  okButton: string,
  cancelButton: string
}

@Injectable()
export class MessagesService {

  constructor( public dialog: MatDialog ) {
  }

  showMessage(message, okButton?: string, cancelButton?: string) {
    if (!okButton) okButton = 'OK'
    if (!cancelButton) cancelButton = ''
    const dialogRef = this.dialog.open(messagesDialog, {
      width: '500px',
      data: {message: message, okButton: okButton, cancelButton: cancelButton}
    });
    return dialogRef
  }

  // showAlert(title: string, type: 'success' | 'error' | 'info') {
  //   const msg = Messenger().post({
  //     message: title,
  //     type: type,
  //     showCloseButton: true,
  //   });    
  // }  

}

@Component({
  selector: 'messageDialog',
  templateUrl: './messageDialog.component.html',
})
export class messagesDialog {

  constructor(public dialogRef: MatDialogRef<messagesDialog>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
  
}