import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


import { superDatabase } from './services/super.database';
import { rootDatabase } from './services/root.database';
import { usersDatabase } from './services/users.database';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    superDatabase,
    rootDatabase,
    usersDatabase
  ]
})

export class databaseModule {}

export { superDatabase };
export { rootDatabase };
export { usersDatabase };