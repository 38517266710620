import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Router, NavigationEnd, RouteConfigLoadEnd } from '@angular/router';
import { userData } from '../../interfaces/interfaces';

@Injectable()
export class usersDatabase {
    currentUser: userData = {}
    users: Array<userData> = []

    constructor(private afs: AngularFirestore, private router: Router){
        this.getUsers().valueChanges().subscribe((users: userData[]) => {
            this.users = users
        })
    }


    getUsers() {
        return this.afs.collection('users')
    }

    getUser(id) {
        return this.afs.collection('users').doc(id)
    }

    getUserFromUID(uid) {
        return this.afs.collection('users', ref => ref.where('uid', '==', uid))
    }

    createUserProfile(user: userData) {
        var message = {
                to: user.firstname + ' ' + user.lastname + '<' + user.email + '>',
                from: "Spillbasar <noreply@spillbasar.no>",
                replyTo: 'post@spillbasar.no',
                templateId: "d-a9e7998e82a1402db921df682d633391",
                dynamic_template_data: {
                  "name": user.firstname + ' ' + user.lastname,
                  "username": user.email,
                  "projectName": 'Spillbasar',
                  "projectEmail": 'post@spillbasar.no',
                  "projectURL": 'admin.spillbasar.no'
                }
        }
        return this.afs.collection('queues/users/users').add({
            email: user.email,
            user_id: user.id,
            message: message
        })
    }

    updateUserProfile(user: userData) {
        var message = {
                to: user.firstname + ' ' + user.lastname + '<' + user.email + '>',
                from: "Spillbasar <noreply@spillbasar.no>",
                replyTo: 'post@spillbasar.no',
                templateId: "d-428e4f5e6e0c4430a016d8d988fa60ad",
                dynamic_template_data: {
                  "name": user.firstname + ' ' + user.lastname,
                  "username": user.email,
                  "magic-link": "?username=" +user.email,
                  "projectName": 'Spillbasar',
                  "projectEmail": 'post@spillbasar.no',
                  "projectURL": 'admin.spillbasar.no'
                }
        }
        return this.afs.collection('queues/users/users').add({
            email: user.email,
            user_id: user.id,
            uid: user.uid,
            message: message
        })
    }  
    
    deleteUserProfile(user: userData) {
        if (user.uid) {
            return this.afs.collection('queues/users/users').add({
                email: user.email,
                user_id: user.id,
                uid: user.uid,
                deleteUser: true
            })        
        } else {
            return false
        }
    }
}