import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';

@Injectable()
export class superDatabase {

    constructor(private afs: AngularFirestore){
        
    }

    getNewRef() {
        return this.afs.firestore.collection('tracks').doc().id
    }

    getTracks() {
        return this.afs.collection('tracks', ref => ref.orderBy('timestamp', 'desc').limit(20))
    }

    getAutocam() {
        return this.afs.collection('autocam', ref => ref.orderBy('timestamp', 'desc').limit(1))
    }
    
    getFaders() {
        return this.afs.collection('faders').doc('faders')
    }

    getSettings() {
        return this.afs.collection('settings').doc('settings')
    }

    getStatements() {
        return this.afs.collection('statements')
    }

    getStatement(id: string) {
        return this.afs.collection('statements').doc(id)
    }

    getPlaylists() {
        return this.afs.collection('playlists')
    }

    getPlaylist(id: string) {
        return this.afs.collection('playlists').doc(id)
    }

    getSupersFromPlaylist(playlistId) {
        return this.afs.collection('supers', ref => ref.where('playlistId', '==', playlistId))
    }

    getSuper(id: string) {
        return this.afs.collection('supers').doc(id)
    }
     
}